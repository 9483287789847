<template>
  <div>
    <div class="page">
      <div class="flex-center" style="position: fixed; right: 10px;top:20px;z-index: 2000;">
        <div style="line-height: 1;font-size:0" @click="openService">
          <div class="christ-a">
              <img src="../assets/pictures/mykefu.png" style="width: 38px;height: 38px;margin-right: 15px">
          </div>
        </div>
        <router-link class="btn lang-btn" to="/lang" v-if="select_lang > 0">
            <span class="iconfont icon-yuyan"></span>
            <span>{{$t('选择语言')}}</span>
        </router-link>
      </div>
      <div class="welcome-page">
        <div class="form-logo animated bounce hinge c2" style="margin: 10% auto">
          <img src="../assets/pictures/logo.png" >
        </div>
        <div class="wel-middle">
          <van-button to="/login" block type="primary">{{ $t("Login") }}</van-button>
          <van-button to="/regist" block type="primary">{{ $t("Register") }}</van-button>
          <div class="form-link">
            <div>
              {{ $t("Don’t have an account yet?") }}
              <router-link to="/regist">{{ $t("Register") }}</router-link>
            </div>
            <!-- <div>
              {{ $t("Download") }}
              <a :href="download_url_android" target="_blank">{{
                $t("Android APP")
              }}</a>
              {{ $t("或") }}
              <a :href="download_url_ios" target="_blank">{{
                $t("IOS APP")
              }}</a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="version">
      {{$t('版本号')}}
    </div>
    <StartupPage ref="StartupPage"></StartupPage>
  </div>
     <!-- </Xuehua> -->
</template>
<script>
// import Xuehua from "@/components/Xuehua";
import StartupPage from "@/components/StartupPage";

export default {
  components: { StartupPage },
  data() {
    return {
      download_url: "",
      download_url_android: "",
      download_url_ios: "",
      select_lang: false,
    };
  },
  methods: {
    openService(){
        window._vue.openService();
    },
  },
  mounted() {
    this.select_lang = localStorage.getItem('app.select_lang') || false;
    this.download_url = localStorage.getItem("app.download_url") || "#";
    this.download_url_android =
      localStorage.getItem("app.download_url_android") || "#";
    this.download_url_ios = localStorage.getItem("app.download_url_ios") || "#";
    this.$refs.StartupPage.show();

    // this.$refs.xh.show();
  },
  created() {},
};
</script>

<style scoped>
.form-link{
  padding: 10px;
  border-radius: 6px;
  width: 70%;
  margin: 0 auto;
  background-color: rgba(0,0,0,0.45);
}
::v-deep.startupPage-wapper {
  z-index: 1;
}
.page{
    position: relative;
    z-index: 2;
}
</style>
